import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { motion } from "framer-motion"

import Arrow from './img/arrow.svg'

export default function HoverableSections({ array }) {
	const [ sectionHovered, setSectionHovered ] = useState(null)
	const navigate = useNavigate()
	
	const HoverUp = params => {
		const { href, i, children, text } = params
		console.log(params)
		return <motion.div
		initial={{ opacity: 0.8, bottom: 0 }}
		animate={ sectionHovered ? sectionHovered === i ? { opacity: 1, bottom: '2px' } : { opacity: 0.5 } : {} }
		onHoverStart={ () => setSectionHovered(i) }
		onHoverEnd={ () => setSectionHovered(null) }
		className="section-a"
		>
		<a onClick={e => {
			e.preventDefault()
			navigate(href)
		}} href={href}> { text } </a>
		</motion.div>
	}
	
	return array[0][0] ? (
			array.map((hoverUp, i) => {
				if(i === 0) return <HoverUp i={i+1} href={hoverUp[0] ?? ""} text={hoverUp[1] ?? ""}/>
				return <>
					<motion.img
					src={Arrow}
					animate={ sectionHovered ? { opacity: 0.5 } : {} }
					initial={{ opacity: 0.9 }}/>
					<HoverUp i={i+1} href={hoverUp[0] ?? ""} text={hoverUp[1] ?? ""}/>
				</>
			}) ) : ""
}