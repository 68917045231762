import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { motion } from "framer-motion"

import API from '../API'

import './Profiles.css'
import Rose from './rose.png'
import Lamp from './lamp.png'

import Gold from '../img/gold.png'
import Graffiti from '../img/graffiti.png'
import Chest from '../img/chest.png'
import Perks from '../img/perks.png'

import PistonSide from '../img/piston_side.png'
import PistonExtract from '../img/piston_extract.png'
import IronBlock from './acacia_log.png'
import Terracotta from '../img/red_stained_glass.png'
import Stone from '../img/stone.png'

import Wood from './birch_planks.png'

export default function Profiles({
	page, opening,
	openMain,
	animate
}) {
	const navigate = useNavigate()
	const [ JSBlocks, setJSBlocks ] = useState([])
	
	const goToForum = () => {
		openMain()
		navigate("/")
	}
	
	useEffect(() => {
		if(!localStorage.getItem('accessToken')) {
			openMain()
			navigate("/")
		}
	}, [])
	
	const clicked = async (n, e) => {
		if(n === 1 || n === 2 || n === 3 || n === 5 || n === 6) {
			const place = e.target.getBoundingClientRect()
			
			JSBlocks.forEach(x => x.remove())
			
			const pointA = place.bottom + 34
			const pointB = place.right + 10
			
			console.log(place)
			
			const parent = document.getElementById(`page`)
			
			const elements = []
			
			for(const i of [0, 1, 2, 3, 4]) {	
				const pistonA = document.createElement('img')
				pistonA.style = 
				`image-rendering:pixelated;
				opacity: 0;
				position:absolute;
				top:${pointA}px;left:${pointB + i * 36}px;width:36px;z-index:3;`
				pistonA.src = PistonSide;
				parent.appendChild(pistonA)
				
				elements.push(pistonA)
				animate(pistonA, { opacity: 1 })
				
				const pistonB = document.createElement('img')
				
				pistonB.style = 
				`image-rendering:pixelated;
				opacity: 0;
				position:absolute;
				top:${pointA-10}px;left:${pointB + i * 36}px;width:36px;z-index:2;`
				pistonB.src = PistonExtract;
				parent.appendChild(pistonB)
				
				elements.push(pistonB)
				animate(pistonB, { opacity: 1 })
				
				const inputBlock = document.createElement('img')
				
				inputBlock.style = 
				`image-rendering:pixelated;
				opacity: 0;
				position:absolute;
				top:${pointA-50}px;left:${pointB + i * 36}px;width:36px;z-index:2;`
				//inputBlock.src = i === 0 ? Terracotta : IronBlock;
				inputBlock.src = IronBlock;
				parent.appendChild(inputBlock)
				
				elements.push(inputBlock)
				animate(inputBlock, { opacity: 1 })
				
				setTimeout(() => {
					animate(pistonB, { top: pointA - 36 })
					animate(inputBlock, { top: pointA - 72 })
				}, 100)
				
				setTimeout(() => {
					animate(pistonA, { opacity: 0 })
					animate(pistonB, { opacity: 0 })
				}, 400)
				
				setTimeout(() => {
					pistonA.remove()
					pistonB.remove()
				}, 1000)
				
				await new Promise(r => setTimeout(r, 50))
			}
			
			const input = document.createElement('input')
			
			input.style = 
			`background: #fff3;
			opacity: 0;
			border: 1px solid #fff;
			border-radius: 0;
			position:absolute;
			height: 23px;
			outline: none;
			font-size: 17px;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			text-indent: 3px;
			top:${pointA-67}px;left:${pointB + 5}px;width:130px;z-index:2;`
			
			const button = document.createElement('img')
			
			button.style =
			`position: absolute;
			opacity: 0;
			width: 17px;
			height: 8px;
			cursor: pointer;
			top:${pointA-57}px;left:${pointB + 9 + 36 * 4}px;z-index:2;`
			button.src = Wood;
			
			const text = document.createElement('a')
			
			text.style =
			`position: absolute;
			opacity: 0;
			font-size: 14px;
			color: white;
			font-weight: 600;
			font-family: Inter;
			top:${pointA-90}px;left:${pointB + 5}px;z-index:2;`
			text.innerHTML = 
			n === 1 ? "Новое имя" :
			n === 2 ? "Новый пароль" :
			n === 3 ? "Новая почта" :
			n === 5 ? "Код привязки" :
			n === 6 ? "Новый никнейм" : "Что блять"
			
			parent.appendChild(input)
			parent.appendChild(button)
			parent.appendChild(text)
			
			elements.push(input)
			elements.push(button)
			elements.push(text)
			
			setTimeout(() => {
				animate(input, { opacity: 1 }, { duration: 0.3 })
				animate(button, { opacity: 1 }, { duration: 0.3 })
				animate(text, { opacity: 1 }, { duration: 0.3 })
			}, 300)
			
			setJSBlocks(elements)
		} else if(n === 4) {
			await API.logout()
			localStorage.removeItem("token")
			localStorage.removeItem("userId")
			openMain()
			navigate("/")
		}
	}
	
	if(page !== 'profiles') return <></>
	
	return (<motion.div
	initial={{ opacity: 0 }}
	animate={
		opening || page !== 'profiles' ? { opacity: 0 } : { opacity: 1 }
	}
	className="profiles">
		<div className="column">
			<div 
			onClick={goToForum}
			className="go-back">
				<a>На форум</a>
			</div>
			<div className="row">
				<div className="account">
					<img src={Rose} className="rose"/>
					<img src={Lamp} className="lamp"/>
					<a className="title">Аккаунт форума</a>
					<div className="border">
					<div className="top">
						Аусие
					</div>
					<div className="bottom">
						<div><a>Почта: </a><a style={{color:'#8CFFA5'}}>bachkac@yandex.ru</a></div>
						<a>Посл. привязка: 1 нед. назад</a>
						<a style={{ marginTop: '7px' }}>На сайте с: 1.03.2022</a>
						<div><a>Принятых жалоб: </a><a style={{color:'#85FF91'}}>0</a></div>
					</div>
					</div>
				</div>
				<div className="profile">
					<a className="title">Игровой профиль</a>
					<div className="border">
					<div className="top">
						Не привязан!
					</div>
					<div className="bottom">
						<div className="rank"><a className="text">Ранг: </a><a className="name">ИГРОК</a></div>
						<div className="inside">
							<div><a>Привязан </a><a style={{ color: '#A3FF9B' }}>1 нед. назад</a></div>
							<div><a>Наиграно </a><a style={{ color: '#C19BFF' }}>12 часов</a></div>
							<div><a>Получено </a><a style={{ color: '#FF9393' }}>0 блокировок</a></div>
						</div>
					</div>
					</div>
				</div>
			</div>
			<div
			style={{ marginBottom: '50px' }}
			className="row">
				<div className="column">
					<div className="buttons">
						<motion.div 
						onClick={e => clicked(1, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Видимое имя</a>
						</motion.div>
						<motion.div 
						onClick={e => clicked(2, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить пароль</a>
						</motion.div>
						<motion.div 
						onClick={e => clicked(3, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить почту</a>
						</motion.div>
						<motion.div 
						onClick={e => clicked(4, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Выйти из аккаунта</a>
						</motion.div>
					</div>
					<div
					style={{ marginTop: '30px' }}
					className="buttons">
						<motion.div 
						onClick={e => clicked(5, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Привязать профиль</a>
						</motion.div>
						<motion.div 
						onClick={e => clicked(6, e)}
						whileHover={{ left: '4px' }}
						className="button">
							<a>Изменить никнейм</a>
						</motion.div>
					</div>
				</div>
				
				<div className="inventory">
					<a className="title">Игровой инвентарь</a>
					<div className="border inside">
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Gold}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Graffiti}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Chest}/>
							<a>0</a>
						</motion.div>
						<motion.div 
						whileHover={{ scale: 1.04 }}
						className="slot">
							<img src={Perks}/>
							<a>0</a>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	</motion.div>)
}