import { TextOnly } from './Field'
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

import { Months, ParseTime } from '../../Time.js'

import { Forward, Back } from '../../components/Icons'

export default function FeedTopic({ post, index }) {
	const navigate = useNavigate()
	
	const date = new Date(post.updated * 1000)
	const month = Months[date.getMonth()]
	const abr = month.length === 3 ? 'МАЙ' : month.slice(0, 3).toUpperCase()
	
	const replies = post.replies.count
	const repliesWordForm = replies === 1 ? 'ответ' : replies > 1 && replies < 5 ? 'ответа' : 'ответов'
	const lastReply = post.replies.last_date
	const repliesTime = !lastReply ? 'никогда' : ParseTime(lastReply)
	
	const URI = "/forum/topic/" + post.topicId + "-" + post.title.replace(/ /g,'-').toLowerCase()
	
	return (<motion.div
		className="post"
		initial={{ width: '300px', opacity: 0 }}
		animate={{ width: 'auto', opacity: 1 }}
		exit=   {{ opacity: 0 }}
		transition={{ delay: index * 0.1 }}>
		
		<div className="post-header colorful-border border-1">
			<div className="post-header-left">
				<div className="post-avatar"
				style={{ 
					background: `url("https://awzone.net/user/avatar/${post.author.id}.jpg"), #777`,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat'
				}}
				></div>
				<div className="post-title">
					<h1 
					
					>{post.title}</h1>
					<div>
						<a>{post.author.name}</a>
						<a className="point"/>
						<a>{date.getDate()} {month} {date.getFullYear()} </a>
					</div>
				</div>
			</div>
			<div className="post-month">
				<a style={{ fontSize: '30px' }}>{ date.getDate() }</a>
				<a>{ abr }</a>
			</div>
		</div>
		<div className="post-content">
			<div className="post-markdown">
			<TextOnly content={post.content}/>
			</div>
			<div className="read-full">
				<motion.a 
				initial={{ opacity: 0.8 }}
				whileHover={{ opacity: 1 }}
				whileTap={{ scale: 0.95 }}
				onClick={ e => { 
					e.preventDefault(); 
					navigate(URI)
				} }
				href={URI}>Открыть пост</motion.a>
			</div>
		</div>
		<div className="post-footer">
			<div className="post-replies-info">
			{replies} {repliesWordForm} - последний {repliesTime}
			</div>
		</div>
	</motion.div>)
}