import React, { useCallback, useMemo, useEffect } from 'react'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'
import { ReactEditor, 
	Editable, 
	withReact, 
	useSlate, 
	useSlateStatic, 
	Slate,
	useSelected,
	useFocused,
} from 'slate-react'
import { motion } from "framer-motion"

import Lock from "./img/lock.svg"

import {
	Node,
	Editor,
	Transforms,
	createEditor,
	Descendant,
	Element as SlateElement,
	Operation,
} from 'slate'

import { withHistory } from 'slate-history'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const empty = [{
	type: 'paragraph', children: [{ text: '' }]
}]

const Input = ({
		textLocalStorage,
		maxSymbols, 
		initial, 
		setInitial,
		topicId,
		setText, 
		setSymbols,
		openLogin,
		isAuthorized
	}) => {
	const renderElement = useCallback(props => <Element {...props} />, [])
	const renderLeaf = useCallback(props => <Leaf {...props} />, [])
	
	const editor = useMemo(() => 
		withTextLimit(maxSymbols, withImages(withEmbeds(withHistory(withReact(createEditor(
		)))))), [])
	
	const initialValue = useMemo(
		() => JSON.parse(localStorage.getItem( textLocalStorage )) || empty, 
		[ initial ]
	)
	
	useEffect(() => {
		if(initial) {
			const point = { path: [0, 0], offset: 0 }
			editor.selection = { anchor: point, focus: point }; // clean up selection
			editor.history = { redos: [], undos: [] }; // clean up history
			setInitial(false)
		}
	}, [initial])
	
	useEffect(() => {
		setText(JSON.stringify(initialValue))
		const text = editor.children.map(x => Node.string(x)).join(' ')
		setSymbols(text.length)
	}, [ initialValue ])
	
  return (
    <Slate
	onChange={value => {
        const isAstChange = editor.operations.some(
          op => 'set_selection' !== op.type
        )
        if (isAstChange) {
          // Save the value to Local Storage.
          const content = JSON.stringify(value)
          localStorage.setItem( textLocalStorage , content)
		  setText(content)
		  const text = editor.children.map(x => Node.string(x)).join(' ')
		  setSymbols(text.length)
        }
      }}
	editor={editor} initialValue={initialValue}>
      <div className="buttons">
        <MarkButton format="bold" icon="B" />
        <MarkButton format="italic" icon="i" />
        <MarkButton format="underline" icon="_" />
        <MarkButton format="code" icon="<>" />
        <BlockButton format="heading-one" icon="h1" />
        <BlockButton format="heading-two" icon="h2" />
        <BlockButton format="block-quote" icon={`,,''`} />
        <BlockButton format="numbered-list" icon="1." />
        <BlockButton format="bulleted-list" icon="•" />
        <BlockButton format="left" icon="<<" />
        <BlockButton format="center" icon="><" />
        <BlockButton format="right" icon=">>" />
        <BlockButton format="justify" icon="|-|" />
        <InsertImageButton/>
      </div>
	  
	  <div className="editable">
      <Editable
		className="textbox"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder=""
        spellCheck
		//onDOMBeforeInput={handleDOMBeforeInput}
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault()
              const mark = HOTKEYS[hotkey]
              toggleMark(editor, mark)
			  return
            }
          }
        }}
      />
	  { isAuthorized ? (<></>) : (
		  <div className="input-block">
			<img src={Lock} unselectable="on"/>
			<div className="row">
				<a className="titled">Хотите добавить ответ?</a>
				<div><a onClick={() => { openLogin(true) }} className="go-auth">Авторизуйтесь</a><a>, это недолго</a></div>
			</div>
		  </div>
	  ) }
	  </div>
    </Slate>
  )
}

const withTextLimit = (maxSymbols, editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    const isAstChange = operation.type !== "set_selection";
    apply(operation);

    if (
      isAstChange &&
      getTextLength(editor) > maxSymbols
    ) {
      const undo = Operation.inverse(operation);
      apply(undo);
    }
  };

  return editor;
};

function getTextLength(editor) {
	return editor.children.map(x => Node.string(x)).join(' ').length
}




const TextOnly = ({ content }) => {
	const renderElement = useCallback(props => <Element {...props} />, [])
	const renderLeaf = useCallback(props => <Leaf {...props} />, [])
	const editor = useMemo(() => 
		withImages(withEmbeds(withHistory(withReact(createEditor())))), [])
	const initialValue = useMemo(() => content)
	
	return (<Slate editor={editor} initialValue={initialValue}>
		<Editable
		className="text-only editable"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>)
}

const withEmbeds = editor => {
	const { isVoid } = editor
	editor.isVoid = element => (element.type === 'video' ? true : isVoid(element))
	return editor
}

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = (props) => {
	const { attributes, children, element } = props
  const style = { textAlign: element.align }
  
  switch (element.type) {
    case 'block-quote':
      return (
        <p style={{...style, 
			marginLeft: '40px', 
			fontWeight: '800', 
			fontStyle: 'italic', 
			fontSize: '15px',
			color: '#bbb' }} {...attributes}>
          {children}
        </p>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
	case 'video':
	  return <VideoElement {...props} />
	case 'image':
	  return <Image {...props} />
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const insertImage = (editor, url) => {
  const text = { text: '' }
  const image: ImageElement = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  })
}

const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const VideoElement = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const { url } = element
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <div
          style={{
            padding: '0px',
            position: 'relative',
          }}
        >
          <iframe
            src={`${url}?title=0&byline=0&portrait=0`}
            frameBorder="0"
            style={{
              width: 'auto'
            }}
          />
        </div>
        <UrlInput
          url={url}
          onChange={val => {
            const path = ReactEditor.findPath(editor, element)
            const newProperties = {
              url: val,
            }
            Transforms.setNodes(editor, newProperties, {
              at: path,
            })
          }}
        />
      </div>
      {children}
    </div>
  )
}

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic()
  const path = ReactEditor.findPath(editor, element)

  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div
        contentEditable={false}
        style={{ 
			display: 'flex',
			minWidth: '100px',
			minHeight: '100px'
		}}
      >
        <img
          src={element.url}
		  style={{
			display: 'block', maxWidth: '100%', maxHeight: '20em',
			boxShadow: selected && focused ? '0 0 0 3px #794AFF33' : 'none'
		  }}
        />
        <div
          active
          onClick={() => Transforms.removeNodes(editor, { at: path })}
		  style={{
			  display: selected && focused ? 'inline' : 'none',
			  position: 'relative',
			  bottom: '10px',
			  right: '27px',
			  color: '#794AFFaa',
			  fontWeight: '1000',
			  fontSize: '32px',
			  cursor: 'pointer'
		  }}
        >
          <a className="delete">⮾</a>
        </div>
      </div>
    </div>
  )
}

const UrlInput = ({ url, onChange }) => {
  const [value, setValue] = React.useState(url)
  return (
    <input
      value={value}
      onClick={e => e.stopPropagation()}
      style={{
        marginTop: '5px',
        boxSizing: 'border-box',
      }}
      onChange={e => {
        const newUrl = e.target.value
        setValue(newUrl)
        onChange(newUrl)
      }}
    />
  )
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <a style={{ textDecoration: 'underline' }}>{children}</a>
  }

  if (leaf.underline_dot) {
    children = <a style={{ textDecoration: 'underline dotted' }}>{children}</a>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  
  const active = isBlockActive(
	editor,
	format,
	TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  
  return (
    <div
	  className="on-icon"
      active={active}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <motion.div className="icon"
	  style={{ opacity: 0.6 }}
	  whileHover={{ opacity: 1 }}
	  animate={ active ? { scale: 1.1, opacity: 1 } : { scale: 1 } }
	  >
		<a>{icon}</a>
	  </motion.div>
    </div>
  )
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  
  const active = isMarkActive(editor, format)
  
  return (
    <div
	  className="on-icon"
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
    <motion.div className="icon"
	  style={{ opacity: 0.6 }}
	  whileHover={{ opacity: 1 }}
	  animate={ active ? { scale: 1.1, opacity: 1 } : { scale: 1 } }
	  >
		<a>{icon}</a>
	  </motion.div>
    </div>
  )
}

const InsertImageButton = () => {
  const editor = useSlateStatic()
  return (
    <div
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Вставьте ссылку на изображение (.png, .jpg)')
        if (url && !isImageUrl(url)) {
          alert('Ссылка не ведет на изображение!')
          return
        }
        url && insertImage(editor, url)
      }}
    >
      <motion.div className="icon"
	  style={{ opacity: 0.6 }}
	  whileHover={{ opacity: 1 }}
	  ><a>PIC</a>
	  </motion.div>
    </div>
  )
}

const InsertVideoButton = () => {
  const editor = useSlateStatic()
  return (
    <div
      onMouseDown={event => {
        event.preventDefault()
        const url = window.prompt('Вставьте ссылку на видео')
        if (url && !isImageUrl(url)) {
          alert('URL is not an image')
          return
        }
        url && insertImage(editor, url)
      }}
    >
      <div className="button"><a>PIC</a></div>
    </div>
  )
}

export { Input, TextOnly }