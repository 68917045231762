import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { motion } from "framer-motion"
import API from '../API.js'

import { Input } from './components/Field'
import { Timer } from '../Time'

import Arrow from './components/img/arrow.svg'

import './Creation.css'


export default function Creation({
	page,
	forum,
	openLogin,
	isAuthorized
}) {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const sectionId = searchParams.get('section')
	const section = forum.sections.find(x=> x.id === +sectionId - 1)
	
	const [ initial, setInitial ] = useState(false)
	const [ title, _setTitle ] = useState(localStorage.getItem("posting-" +sectionId+ "-title") ?? "")
	const [ text, setText ] = useState("")
	const [ symbols, setSymbols ] = useState(0)
	const [ timer, setTimer ] = useState(localStorage.getItem("send-limit") !== null)
	
	const navigate = useNavigate()
	const location = useLocation()
	
	const send = async () => {
		if(!localStorage.getItem("token") || !text.length) return
		const response = await API.create(section.id, title, JSON.parse(text))
		console.log(response)
		
		if(!response.posts?.[0]) return
		
		localStorage.setItem("send-limit", Date.now() + 60000)
		localStorage.removeItem('posting-' + sectionId)
		localStorage.removeItem('posting-' + sectionId + '-title')
		
		navigate("/forum/topic/" + response.posts[0].topicId + '-' + response.posts[0].title.replace(/ /g,'-'))
	}
	
	useEffect(() => {
		if(!localStorage.getItem("token")) navigate(sectionURI)
	}, [])

	const setTitle = e => {
		console.log(e)
		if(e.target.value.length > 30) return e.preventDefault()
		_setTitle(e.target.value)
		localStorage.setItem("posting-" + sectionId + "-title", e.target.value)
	}
	
	
	
	const HoverUp = params => {
		const { href, i, children, text } = params
		console.log(params)
		return <motion.div
		initial={{ opacity: 0.8, bottom: 0 }}
		animate={ sectionHovered ? sectionHovered === i ? { opacity: 1, bottom: '2px' } : { opacity: 0.5 } : {} }
		onHoverStart={ () => setSectionHovered(i) }
		onHoverEnd={ () => setSectionHovered(null) }
		className="section-a"
		>
		<a onClick={e => {
			e.preventDefault()
			navigate(href)
		}} href={href}> { text } </a>
		</motion.div>
	}
	
	const [ hoverUps, setHoverUps ] = useState([[],[],[],[]])
	const [ sectionHovered, setSectionHovered ] = useState(null)
	
	useEffect(() => {
		const theme = forum.themes[section.theme]
		
		setHoverUps([
		["/forum","Разделы"],
		["/forum",theme.name],
		[sectionURI,section.name]
		])
	}, [])
	
	const sectionURI = ("/forum/" + (section?.id+1) + '-' + section?.name).replace(/ /g,'-').toLowerCase()
	
	if(page !== 'creation') return <></>
	
	return (<div className="creation">
	
		<div className="section-bar">
			<a>
			{
				hoverUps[0][0] ? (
				hoverUps.map((hoverUp, i) => {
					if(i === 0) return <HoverUp i={i+1} href={hoverUp[0] ?? ""} text={hoverUp[1] ?? ""}/>
					return <>
						<motion.img
						src={Arrow}
						animate={ sectionHovered ? { opacity: 0.5 } : {} }
						initial={{ opacity: 0.9 }}/>
						<HoverUp i={i+1} href={hoverUp[0] ?? ""} text={hoverUp[1] ?? ""}/>
					</>
				}) ) : ""
			}
			</a>
		</div>
	
	<div className="topper shadowed">
			<div className="description">
				<div>Прежде, чем отправлять тему, ознакомьтесь с <a onClick={() => {
					window.location.href = "https://pornhub.com"
				}}>Правилами публикации</a></div>
			</div>
		</div>
		
		<div className="title">
			<a className="outbox-text">Заголовок</a>
			<a className="symbols-left">{ 30 - title.length }</a>
		</div>
		
		<motion.div className="title-input">
			<div className="background"/>
			<motion.div
			whileHover={{ opacity: 1 }}
			className="white-background"/>
			<motion.input value={title} onChange={setTitle}/>
		</motion.div>
		
		<motion.div className="editor">
		{
			<Input
			textLocalStorage={"posting-" + sectionId}
			initial={initial}
			setInitial={setInitial}
			openLogin={()=>{}}
			isAuthorized={true}
			topicId={0}
			maxSymbols={3000}
			setText={setText}
			setSymbols={setSymbols} />
		}
		</motion.div>
		<div className="preview">
			<a className="edit-text">{'Редактирование' }</a>
			<div className="right">
				<a className="symbols">{
					timer ? <><a>`Подождите задержку: `</a><Timer name="send-limit" setTimer={setTimer}/></>
					: `Осталось символов: ${ 3000 - symbols }`
				}</a>
				<motion.div className="send"
				whileHover={{ scale: 1.02 }}
				whileTap={{ scale: 0.96 }}
				onClick={send}
				><a>Отправить</a></motion.div>
			</div>
		</div>
	</div>)
}