import React, { Component, useState, useEffect } from 'react'
import Main from './Main';
import Panel from './main/Panel';
import Sections from './forum/Sections';
import Feed from './forum/Feed';
import Footer from './main/Footer';
import Topic from './forum/Topic';
import Section from './forum/Section';
import Creation from './forum/Creation';
import API from './API'

import { useSearchParams, useLocation } from 'react-router-dom'

import { motion, useAnimate, AnimatePresence } from "framer-motion"
import { easeIn } from "framer-motion"


function scrollTo(pageEl, duration) {
	var e = document.getElementById("page")
    scrollToC(e, e.scrollTop, pageEl, duration);
}

function scrollToC(e, from, to, duration) {
    if (duration <= 0) return;
    if(typeof from === "object")from=from.offsetTop;
    if(typeof to === "object")to=to.offsetTop;

    scrollToX(from, to, 0, 1/duration, 20, easeOutCuaic);
}

async function scrollToX(xFrom, xTo, t01, speed, step) {
	const pageEl = document.getElementById("page")
	if(!pageEl) return
	
	if (t01 < 0 || t01 > 1 || speed<= 0) {
		pageEl.scrollTop = 0;
		return;
	}
	pageEl.scrollTop = xFrom - (xFrom - 0) * easeOutCuaic(t01);
	t01 += speed * step;

	await new Promise(requestAnimationFrame)
	scrollToX(xFrom, 0, t01, speed, step);
}

function easeOutCuaic(t) {
   t--;
   return t * t * t + 1;
}

export default function App() {
	const [ screenWidth, setScreenWidth ] = useState(window.innerWidth)
	const [ scope, animate ] = useAnimate()
	
	// Страница - главная, форум, жалобы, ...
	const [ page, setPage ] = useState(null)
	const [ opening, setOpening ] = useState(false)
	
	// Открытая верхняя страница
	const [ wrong, _setWrong ] = useState(null)
	const [ topOpened, setTopOpened ] = useState(false)
	
	// Состояние загрузки (отображае
	const [ loading, setLoading ] = useState('stale')
	
	// Формы поверх всех других
	const [ special, setSpecial ] = useState('disabled')
	
	// Хранилище разделов и онлайна (сохраняется и в localStorage)
	const [ forum, setForum ] = useState(null)
	const [ online, setOnline ] = useState(null)
	
	const [ authorized, setAuthorized ] = useState(null)
	
	const setWrong = async value => {
		if(wrong !== null) {
			animate(".error-text", { opacity: 0 }, { duration: 0.1 })
			await new Promise(res => setTimeout(res, 200))
		}
		animate(".error-text", { opacity: 1 }, { duration: 0.3 }); _setWrong(value)
	}
	const clearWrong = () => { animate(".error-text", { opacity: 0 }) }
	
	// Анимированное перелистывание
	const location = useLocation()
	const [ search, setSearch ] = useSearchParams()
	
	useEffect(() => {
		const path = location.pathname
		
		console.log('PATHNAAAAAAAAAAAME')
		
		// Сбрасываем данные текущей страницы
		let currentPageName = null
		
		if(path.match(/\/forum[/]topic[/](.*)/)) currentPageName = 'topic'
		else if(path.match(/\/forum[/][0-9]-(.*)$/)) {
			currentPageName = 'section'
		}
		else if(path.match(/\/forum\/posting/)) currentPageName = 'creation'
		else if(path.match(/\/forum$/)) currentPageName = 'sections'
		else if(path.match(/\/me$/)) {
			openTop(true)
			currentPageName = 'profiles'
		}
		else if(path === "\/") currentPageName = 'feed'
	
		setOpening(currentPageName)
		
		console.log('DATA LOADDDDDDDDDDDDD')
		
		const timeout = setTimeout(() => {
			setPage(currentPageName)
			setOpening(false)
		}, 300)
		
		return () => clearTimeout(timeout)
	}, [ location.pathname ])
	
	useEffect(() => {
		const route = search.get('route')
		if(route === 'signin') openRegister(true)
		else if(route === 'login') openLogin(true)
	}, [])
	
	useEffect(() => {
		console.log('AUTHORIZED ' + localStorage.getItem("token"))
		setAuthorized(localStorage.getItem("token") !== null)
	}, [ authorized ])
	
	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	}
	
	useEffect(() => {
		const keepOnline = setInterval(async () => {
			console.log('keeping online')
			await API.keep_online()
			setOnline(JSON.parse(localStorage.getItem("online")))
		}, 30000)
		
		console.log('< online keeper started >')
		
		return () => {
			console.log('< clearing online keeper >')
			clearInterval(keepOnline)
		}
	}, [])
	
	useEffect(() => {
		API.update_sections(setForum);
		(async() => {
			await API.keep_online()
			setOnline(JSON.parse(localStorage.getItem("online")))
		})();
	}, [])
	
	useEffect(() => {
		window.addEventListener('resize', handleResize)
		
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	
	const openMain = () => {
		setTopOpened(false)
		
		animate(".panel-login", { opacity: 0, marginTop: '-100px', display: 'none' }, {
			duration: 0.4
		})
		
		animate(".auth", { opacity: 0, marginTop: '-100px' }, { duration: 0.4, delay: 0.1 })
		animate(".page", { overflowY: 'scroll' })
		
		animate(".server-title .row", { margin: '0 0 0 200px' }, { duration: 0 })
		animate(".server-title", { display: 'flex' }, { duration: 0.4 })
		
		setTimeout(() => {
			animate(".server-title .row", { opacity: 1, margin: '0' }, {
			delay: 0.5,
			duration: 1.3, ease: [0.29, 0, 0.46, 1] })
		}, 150)
			
		animate(".header", { height: screenWidth > 620 ? '300px' : '200px' }, { 
			duration: screenWidth > 900 ? 2.5 : 3, ease: [0.1, 0.25, 0.1, 1]
		})
		
		animate(".header .background", { height: screenWidth > 620 ? '350px' : '200px' }, {
			duration: 3, ease: [0.1, 0.25, 0.1, 1]
		})		

		animate(".light-bg", { opacity: 0.3 }, { duration: 2 })
		animate(".forum .back", { opacity: 1 }, { duration: 2 })
		animate(".darking", { opacity: 1 }, { duration: 2 })
	}
	
	const pageEl = document.getElementsByClassName("page")[0]
	
	const openTop = (instant) => {
		scrollTo(pageEl, 3000);
		setTopOpened(true)
		
		animate(".page", { overflowY: 'hidden' })
		animate(".header", { overflowY: 'visible' })
		
		animate(".server-title .row", { 
			opacity: 0,
			margin: '0px 300px 0 0' 
		}, {
			duration: instant === true ? 0 : 0.4
		})
		
		animate(".server-title", {
			display: 'none'
		}, { duration: instant === true ? 0 : 0.4 })
		
		animate(".header", { height: '100%' }, {
			duration: instant === true ? 0 : 1.5, ease: [0.27, 0.4, 0.3, 1]
		})
		
		animate(".header .background", { height: '100%' }, {
			duration: instant === true ? 0 : 1.5, ease: [0.27, 0.4, 0.3, 1]
		})
		
		animate(".main-page-background", { opacity: 0 }, { duration: 1.2 })
		
		animate(".auth", { opacity: 1, marginTop: 0 }, {
			duration: 0.5,
			delay: 0.3
		})
		
		clearWrong()
	}
	
	const openLogin = instant => {
		setSearch("route=login")
		openTop(instant)
		
		animate(".panel-login", { opacity: 1, marginTop: '100px', display: 'flex' }, {
			duration: 0.5, delay: 0.6
		})
		
		animate(".panel-register", { opacity: 0, marginTop: '-100px', display: 'none' }, { duration: 0.4 })
		
		clearWrong()
	}
	
	const openRegister = instant => {
		setSearch("route=signin")
		openTop(instant)
		
		animate(".panel-login", { opacity: 0, marginTop: '-100px', display: 'none' }, {
			duration: 0.4
		})
		
		animate(".panel-register", { opacity: 1, marginTop: '100px', display: 'flex' }, {
			duration: 0.5,
			delay: 0.6
		})
		
		
		clearWrong()
	}
	
	
	return (<div ref={scope}>
		<div className="page" id="page">
			{/* Top page - login, sign in, profiles */}
			<Main screenWidth={screenWidth}
			animate={animate}
			opening={opening}
			page={page} setPage={setPage}
			loading={loading} setLoading={setLoading}
			special={special} setSpecial={setSpecial}
			openMain={openMain}
			openLogin={openLogin}
			openRegister={openRegister}
			openTop={openTop}
			topOpened={topOpened}
			wrong={wrong} setWrong={setWrong}
			authorized={authorized} setAuthorized={setAuthorized}
			/>
			
			{/* Forum bar and forum content */}
			{/* перелистывается при открытии страниц сверху */}
			<div className={`forum`}>
				<div className={`back main-page-background`}/>
				<Panel opening={opening} page={page} screenWidth={screenWidth}/>
				<motion.div
				animate={opening ? { opacity: 0 } : { opacity: 1 }}
				className="content"
				style={{}}>
					<div className={`light-bg main-page-background`}/>
						{
							page === 'topic' || opening === 'topic' ? 
								<Topic online={online} 
								page={page}
								authorized={authorized} 
								openLogin={openLogin} 
								animate={animate} 
								forum={forum}/> : ''
						}
						{
							page === 'feed' || opening === 'feed' ?
								<Feed
								screenWidth={screenWidth}
								page={page} 
								online={online}/> : ''
						}
						{
							page === 'sections' || opening === 'sections' ? 
								<Sections 
								page={page} 
								online={online} 
								setForum={setForum} 
								forum={forum}/> : ''
						}
						{
							page === 'section' || opening === 'section' ? 
								<Section 
								page={page}
								online={online}
								forum={forum}/> : ''
						}
						{
							page === 'creation' || opening === 'creation' ? 
								<Creation
								page={page}
								forum={forum}/> : ''
						}
					
				</motion.div>
			</div>
			<Footer/>
		</div>
	</div>
	)
}