import Arrow from './arrow.svg'
import _Forward from './forward.svg'

function Back({  }) {
	return <img style={{ transform: 'rotate(180deg)' }} src={Arrow}/>
}

function Forward({ color, isSmall }) {
	return <a style={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: '1000' }}>→</a>
}

export {
	Forward,
	Back
}