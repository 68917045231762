import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { motion } from "framer-motion"
import API from '../API.js'

import { ParseTime } from '../Time'

import HoverableSections from './components/HoverableSections'
import InfoBlock from './components/InfoBlock.js'

import Arrow from './components/img/arrow.svg'
import "./Section.css"

export default function Section({
	page,
	forum,
	online,
}) {
	const [ search, setSearch ] = useSearchParams()
	const navigate = useNavigate()
	const location = useLocation()
	
	//const [ id, setId ] = useState(null)
	const [ topics, setTopics ] = useState(null)
	const [ stats, setStats ] = useState(null)
	
	const [ title, setTitle ] = useState("")
	const [ pages, setPages ] = useState(null)
	const [ visible, setVisible ] = useState([])
	
	const [ actionsOpenedFor, setOpenedActions ] = useState(null)
	
	const [ initial, setInitial ] = useState(false)
	const [ updateInput, setUpdateInput ] = useState(false)
	
	const [ sectionHovered, setSectionHovered ] = useState(null)
	const [ hoverUps, setHoverUps ] = useState([[],[],[],[]])
	
	const goPosting = () => {
		navigate("/forum/posting?section=" + (section.id + 1))
	}
	
	const openTopic = (e, topic) => {
		e.preventDefault()
		navigate("/forum/topic/" + topic.topicId + '-' + topic.title.replace(/ /g,'-').toLowerCase())
	}
	
	const HoverUp = params => {
		const { href, i, children, text } = params
		console.log(params)
		return <motion.div
		initial={{ opacity: 0.8, bottom: 0 }}
		animate={ sectionHovered ? sectionHovered === i ? { opacity: 1, bottom: '2px' } : { opacity: 0.5 } : {} }
		onHoverStart={ () => setSectionHovered(i) }
		onHoverEnd={ () => setSectionHovered(null) }
		className="section-a"
		>
		<a onClick={e => { e.preventDefault(); navigate(href) }} href={href}> { text } </a>
		</motion.div>
	}
	
	useEffect(() => {
		console.log('EFFECTING BLYAAAAAAAAAAAAAAAAAAAAD')
		
		API.section(id, pageNum).then(data => {
			console.log('PIZDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
			if(data.route === "wrong") return window.location.reload()
			if(data.id === "wrong") return// navigate("/forum")
			setTopics(data.topics)
			setStats(data.stats)
		})
		
		return () => {}
	}, [])
	
	console.log('LOADING SUKAAAAAAAAAAAAAAAAAAAAAAAA')
	
	const id = useMemo(() => +(location.pathname.split('/')[2].split('-')[0]) - 1, [])
	const pageNum = +search.get("page")
	const section = forum.sections[id]
	
	console.log(id)
	
	if(topics === null || page !== 'section') return <></>
	
	return (<div className="section">
	<div className="section-bar">
		{
			<HoverableSections forum={forum} array={[
				[ "/forum", "Разделы" ],
				[ "/forum", forum.themes[section.theme]?.name],
				[ "/forum/" + (section.id + 1) + "-" + section.name.toLowerCase().replace(/ /g,'-'), section.name ],
			]}/>
			
		}
		</div>
		<div className="sec-header shadowed">
			<div className="row">
				<a className="title">{ section?.name }</a>
				<div className="sub">
					<a>Подписаться на раздел</a>
				</div>
			</div>
			<hr/>
			<div className="description">
				<a>{ section?.about }</a>
			</div>
		</div>
		<div className="sec-row">
			<div className="posts">
				<div className="sec-actions">
					{ /* Страницы, сортировка, создать тему */ }
					<div className="pages">
						<a
						href={ () => setSearch("page=1") }
						className="page-btn shadowed">1</a>
					</div>
					<div className="topic-actions">
					<div className="sort shadowed">
						<a>Сортировка</a>
					</div>
					<div className="create shadowed"
					onClick={ goPosting }>
						<a
						href="/forum/posting"
						>Создать тему</a>
					</div>
					</div>
				</div>
				<div className="posts-content">
					{ topics?.length ? topics.map(topic => {
						return <motion.div
							key={`topic_${topic.topicId}`}
							className="sec-topic shadowed"
							initial={{ opacity: 0, top: '30px' }}
							animate={{ opacity: 1, top: 0 }}
							>
							<div 
							onClick={ e => openTopic(e, topic) }
							className="right">
							<a className="top">{ topic.title }</a>
							<div className="bottom">
								Автор: <a className="authorname">{ topic.author.name }</a>, 9 июня
							</div>
							</div>
							{ /* TODO последние ответы */ }
							<div className="left">
								<div className="last-reply">
								<a className="replier">{ topic.reply.author.name }</a>
								<a className="replied">{ ParseTime(topic.reply.createdAt) }</a>
								</div>
								<div
								style={{ 
									background: `url("https://awzone.net/user/avatar/${topic.author.id}.jpg"), #777`,
									backgroundSize: 'contain',
									backgroundRepeat: 'no-repeat'
								}}
								className="avatar"></div>
								<div className="stats">
									<a>{topic.replies} ответов</a>
									<a>{topic.views} просмотр
									{ topic.views === 1 ? '' : topic.views > 0 && topic.views < 5 ? 'а' : 'ов' }</a>
								</div>
							</div>
						</motion.div>
					}) : topics ? <div className="sec-empty">
						<a>Тем пока еще нет!</a>
					</div> : ''}
				</div>
			</div>
			<div className="info">
			<InfoBlock
					index={0}
					style={{ padding: '20px 20px' }}
					header="Статистика раздела"
					content={<div className="serverList">
							<a>Всего сообщений: { stats.posts }</a>
							<a>Новых тем: { stats.newtopics }</a>
						</div>
					}
					footer={
						<p>Последняя реакция: 0 мин. назад</p>
					}/>
			{online ? (<InfoBlock  index={1}
					style={{ padding: '20px 20px' }}
					header="Страницу просматривают"
					content={
						
							(() => {
							const usersOnline = online.users.filter(x => x.href === 'section-' + section.id)
							return usersOnline.length ? usersOnline.map(x => x.name).join(', ') : 'Никто'
							})()
						
					}
					footer={
						<p>{
							`А также ` + online.guests.filter(x=> x.href === 'section-' + section.id).length + ` гостей`
						}</p>
					}/>) : '' }
			<InfoBlock 
					index={2}
					style={{ padding: '20px 20px' }}
					header="Уведомления"
					content={<div className="serverList">
							<a>Подписок на темы не найдено</a>
						</div>
					}
					footer={
						<p>Средняя активность: 5 ув. / день</p>
					}/>
			</div>
		</div>
	</div>)
}