import { motion, useAnimate } from "framer-motion"
import { useCallback, useMemo, useEffect } from 'react'

import { Input, TextOnly } from './Field'

import Message from './img/message.png'
import Like from './img/like.svg'
import Dislike from './img/dislike.svg'
import Fav from './img/love.svg'

import PostActions from './img/post-actions.svg'
import Delete from './img/delete.svg'
import Edit from './img/edit.svg'
import Report from './img/report.svg'

import { Months, ParseTime } from '../../Time.js'

import Love from './img/sticker.svg'



export default function TopicPost({
	sendEdit,
	editText,
	setEditText,
	editFor,
	online, 
	authorized, 
	editPost, 
	deletePost, 
	reportPost, 
	switchActions, 
	animate, 
	index, 
	post 
}) {
	const userId = localStorage.getItem('userId')
	
	if(editFor === post.id) {
	return <motion.div
	key={post.id}
	id={`id_${post.id}`}
	whileHover={{ opacity: 1 }}
	animate={{ opacity: (post.title ? 1 : 0.8) }}
	initial={{ opacity: 0 }}
	className={`topic edit-state light-back-line` + (post.title ? ' main-post box-shadow' : '')}>
		<div className={`author` + (online.users.find(x=>x.name===post.author.name) ? ' online' : '')}>
			<a>{ post.author.name }</a>
			<div className="avatar"
			style={{ 
				background: `url("https://awzone.net/user/avatar/${post.author.id}.jpg"), #777`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat'
			}}
			></div>
			<a className="user-rank">{ post.author.stats.sub }</a>
			<div className="user-stats"><img src={Message}/>{ post.author.stats.msg }</div>
		</div>
		<div className="topic-content" style={{ padding: '0' }}>
			<div className="editor">
			<Input
			textLocalStorage={'edit'}
			openLogin={() => {}}
			isAuthorized={true}
			topicId={post.topicId}
			setText={setEditText}
			maxSymbols={post.title ? 3000 : 2000}
			setSymbols={() => {}} />
			</div>
			<div className="preview" style={authorized ? {} : {}}>
				<div className="right">
					<a style={{cursor:'pointer'}} onClick={() => editPost(null)} className="symbols">Отменить</a>
					<motion.div className="send"
					whileHover={{ scale: 1.02 }}
					whileTap={{ scale: 0.96 }}
					onClick={sendEdit}
					><a>Изменить</a></motion.div>
				</div>
			</div>
		</div>
		
	</motion.div>
	}
	
	const likes = post.reaction.filter(x => x[1] === 0).length
	const dislikes = post.reaction.filter(x => x[1] === 1).length
	const favs = post.reaction.filter(x => x[1] === 2).length
	
	const timestamp = post.edit ? (
			post.edit.total === 1 ? `Изменено: ${ParseTime(post.edit.at * 1000)}`
			: (`Изменено ${post.edit.total} раз`
			+ `${post.edit.total>1&&post.edit.total<5?'а':''}, последний ${ParseTime(post.edit.at * 1000)}`))
			: `Опубликовано: ${ParseTime(post.updated * 1000)}`
	
	return <motion.div
	key={post.id}
	id={`id_${post.id}`}
	whileHover={{ opacity: 1 }}
	animate={{ opacity: (post.title ? 1 : 0.8) }}
	initial={{ opacity: 0 }}
	className={`topic light-back-line` + (post.title ? ' main-post box-shadow' : '')}>
		<div className={`author` + (online.users.find(x=>x.name===post.author.name) ? ' online' : '')}>
			<a>{ post.author.name }</a>
			<div className="avatar"
			style={{ 
				background: `url("https://awzone.net/user/avatar/${post.author.id}.jpg"), #777`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat'
			}}
			></div>
			<a className="user-rank">{ post.author.stats.sub }</a>
			<div className="user-stats"><img src={Message}/>{ post.author.stats.msg }</div>
		</div>
		<div className="topic-content">
			<a className="timestamp">
			{ timestamp }
			</a>
			
			<TextOnly content={post.content}/>
			
			<div className="reactions">
			{
				post.title ? <div className="hr"/> : (<></>)
			}
			
			<div className="icons">
			
			</div>
			
			<div className="show-reactions">
				<motion.img src={Love}
				id={'reactions-icon-' + post.id}
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.95 }}
				/>
			</div>
			
			</div>
			<div className="show-actions">
				<motion.img
				id={'actions-icon-' + post.id}
				whileHover={{ scale: 1.1 }}
				onClick={switchActions}
				src={PostActions}/>
				<div id={`actions-block-${post.id}`} className="actions-block">
					{ authorized && +userId === post.author?.id ? (<>
					<motion.div
					initial={{ margin: 0, opacity: (post.createdAt + 1000 * 60 * 60 * 24 > Date.now()) ? 0.5 : 1  }}
					whileHover={{ marginLeft: '4px' }}
					whileTap={{ scale: 0.95 }}
					onClick={() => { deletePost(post.id) }}
					className="actions-row"><div className="icon"><img src={Delete}/></div><a>Удалить</a></motion.div>
					<motion.div
					initial={{ margin: 0 }}
					whileHover={{ marginLeft: '4px' }}
					whileTap={{ scale: 0.95 }}
					onClick={() => { editPost(post.id) }}
					className="actions-row"><div className="icon"><img src={Edit}/></div><a>Изменить</a></motion.div>
					</>) : authorized ? (<>
					<motion.div
					initial={{ margin: 0 }}
					whileHover={{ marginLeft: '4px' }}
					whileTap={{ scale: 0.95 }}
					onClick={() => { reportPost(post.id) }}
					className="actions-row"><div className="icon"><img src={Report}/></div><a>Репорт</a></motion.div>
					</>) : (<>
					<motion.div
					initial={{ margin: 0 }}
					whileTap={{ scale: 0.95 }}
					className="actions-row"><div className="icon"><img src={Report}/></div><a style={{fontSize:'13px'}}>Авторизуйтесь</a></motion.div>
					</>)}
				</div>
			</div>
		</div>
		
	</motion.div>
}