import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { motion } from "framer-motion"
import API from '../API.js'

import './Feed.css'

import Diamond from './img/Diamond.svg'
import Crafter from './img/Crafter.svg'
import Slime from './img/Slime.png'
import SlimeVid from './img/output.webm'

import Discord from './img/Discord.svg'
import Telegram from './img/Telegram.svg'
import VK from './img/VK.svg'

import Download from './img/Download.svg'

import InfoBlock from './components/InfoBlock.js'
import FeedTopic from './components/FeedTopic.js'

export default function Feed({
	screenWidth,
	page,
	online
}) {
	const [ posts, setPosts ] = useState(null)
	const [ scrollToDownload, setScrollIntoDownload ] = useState(false)
	
	useEffect(() => {
		API.get_feed().then(feed => {
			if(!feed[0]) return// window.location.reload()
			else setPosts(feed)
		})
	}, [])
	
	useEffect(() => {
		console.log(page)
		console.log(window.location.hash)
		
		setTimeout(() => {
			if(page === 'feed' && window.location.hash === "#launcher") {
				document.getElementById("launcher").scrollIntoView();
			}
		}, 500)
	}, [ page ])
	
	function openInNewTab(url) {
		window.open(url, '_blank').focus();
	}
	
	const downloadLauncher = () => {
		fetch('https://www.awzone.net/overfox.exe', {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/pdf',
			},
		  })
		  .then((response) => response.blob())
		  .then((blob) => {
			const url = window.URL.createObjectURL(
			  new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
			  'download',
			  `Overfox Launcher.exe`,
			);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		  });
	}
	
	if(page !== 'feed') return <></>
	
	return (<div className="main">
			<div className="row">
				<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.5, ease: [0.27, 0.4, 0.3, 1] }}
				className="items">
					<h2 className="top">ArtWorld — это...</h2>
					<HoverablePresentItem
					index={0}
					image={Diamond}
					text={<>
						<a>ArtWorld — это новые, активно развивающиеся игровые сервера
						с упором на уникальный геймплей и свободное творчество</a>
					</>}
					className="present-item A"/>
					
					<div style={{ height: '40px' }}/>
					
					<HoverablePresentItem
					index={1}
					image={Crafter}
					text={<>
						<a>Разворачивая свой первый сервер в Minecraft, мы предлагаем
						вам опробовать собственные режимы Creative и SkyWars
						с неповторимыми механиками и программированием</a>
					</>}
					className="present-item B"/>
					
					<div style={{ height: '40px' }}/>
					
					<HoverablePresentItem
					index={2}
					image={Slime}
					animated={true}
					text={<>
						<a>Присоединяйся!</a>
						<a>В разработке находятся десятки новых мини-игр, и 
						мы будем рады любой поддержке со стороны игроков :)</a>
					</>}
					className="present-item C"/>
					
					<motion.div
					initial={{ left: -100, opacity: 0 }}
					animate={{ left: 0, opacity: 1 }}
					transition={{ delay: 0.3 }}
					className="overfox">
					<h2 id="launcher">Начать игру? Проще, чем найти алмазы!</h2>
					<div className="overfox-panel">
						<div className="text"><div>Для ArtWorld был 
						разработан лаунчер на <a style={{ color: '#FFFCDF' }}>
						современном фреймворке кроссплатформенных приложений</a>
						, который не поддается взлому.</div>
						<div><a style={{ color: '#CEFFFC' }}>Overfox Launcher </a> 
						впитал в себя множество полезных функций, и включает в себя редактор сборок, 
						быструю авторизацию и качественное шифрование паролей.</div>
						<hr/>
						<a>На момент релиза лаунчер проверялся только на устройствах с Windows 10</a>
						<a>Вы можете помочь с тестированием на других устройствах, 
						связавшись с разработчиком в Discord</a>
						</div>
						<motion.div
						whileHover={{ scale: 1.02 }}
						whileTap={{ scale: 0.98, opacity: 0.8 }}
						onClick={ downloadLauncher }
						className="download">
							<img src={Download}/><a>Overfox Launcher</a>
						</motion.div>
					</div>
					</motion.div>
				</motion.div>
		{ screenWidth < 600 ? posts ? <div style={{ marginTop: '-30px' }} className="feed">
		<h2>Последние новости</h2>
		{posts.map((post, i) => {
		return <FeedTopic index={i} key={`feed_` + i} post={post}/>
		})}</div> : "" : "" }
				<div className="feed-info info">
					<InfoBlock 
								index={1}
								style={{ padding: '15px 25px' }}
								header="Режимы"
								content={<div className="serverList">
										<a>Creative+ - закрытый Beta!</a>
										<a>Survival - в разработке!</a>
										<a>SkyWars+ - в разработке!</a>
									</div>
								}
								footer={
									<p>Аптайм за 24 часа - 99,8%</p>
								}/>
					{online ? <InfoBlock  index={2}
						style={{ padding: '20px 20px' }}
						header="Пользователи онлайн"
						content={
							
								(() => {
								const usersOnline = (online.users ?? [])
								return usersOnline.length ? usersOnline.map(x=>x.name).join(', ') : 'Никого'
								})()
							
						}
						footer={
							<p>{
								`А также ` + (online.guests ?? []).length + ` гостей`
							}</p>}/> : '' }
					<InfoBlock  index={3}
								style={{ padding: '15px 20px' }}
								header="Социальные сети"
								content={<div className="socials">
										<img src={Discord} onClick={() => openInNewTab("https://discord.gg/MC6ccff")}/>
										<img src={VK} onClick={() => openInNewTab("https://vk.com/artworld")}/>
										<img src={Telegram} onClick={() => openInNewTab("https://t.me/rezaneteam")}/>
									</div>}/>
				</div>
			</div>
		{ screenWidth >= 600 ? posts ? <div className="feed">
			<h2>Последние новости</h2>
			{posts.map((post, i) => {
			return <FeedTopic index={i} key={`feed_` + i} post={post}/>
			})}</div> : "" : "" }
	</div>)
}

function HoverablePresentItem({ index, image, animated, text, className }) {
	const [ animate, setAnimate ] = useState(false)
	
	useEffect(() => {
		if(!animated) return;
		
		const interval = setInterval(() => {
			if(!animate) startAnimation()
		}, 20000)
		
		return () => clearInterval(interval)
	}, [ animate ])
	
	const startAnimation = () => {
		const gif = document.createElement('video')
		gif.style = `position:absolute;left:-25.5px;bottom:-41.5px;width:120px;height:230px;`
		gif.src = SlimeVid;
		gif.autoplay = true
		const parent = document.getElementById(`hoverable_icon_${index}`)
		
		parent.appendChild(gif)
		
		setAnimate(true)
		
		setTimeout(() => {
			setAnimate(false)
			setTimeout(() => {
				gif.remove()
			}, 100)
		}, 2000)
	}
	
	return <motion.div
	whileTap={{ scale: 0.99, opacity: 0.8 }}
	initial={{ opacity: 0, left: -100 }}
	animate={{ opacity: 1, left: 0, transition: { delay: index * 0.1 } }}
	className={className}>
		<div id={`hoverable_icon_${index}`} style={{ position: 'relative' }} className="icon">
			<motion.img
			style={animate ? { opacity: 0 } : {}}
			whileHover={animated ? { scale: 0.97 } : {}}
			onClick={animated ? () => startAnimation() : () => {}}
			src={image}/>
		</div>
		<div>
			{ text }
		</div>
	</motion.div>
}