import { motion } from "framer-motion"

export default function InfoBlock({ index, style, header, content, footer }) {
	return (
	<motion.div
		initial={{ opacity: 0, left: 100 }}
		animate={{ opacity: 1, left: 0 }}
		transition={{ delay: index * 0.1 }}
		className="online info-block">
		<header className="info-block-topper colorful-border border-2">
		<a>{header}</a>
		</header>
		<div
		style={style}
		className="info-block-content">
			<div className="info-block-inside">{content}</div>
		</div>
		{ footer !== undefined ? 
			<footer className="info-block-footer">
			<a>{footer}</a>
			</footer>
		 : "" }
	</motion.div>
	)
}