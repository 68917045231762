import { useState, useEffect, useMemo } from 'react'
const Months = "января февраля марта апреля мая июня июля августа сентября октября ноября декабря".split(" ")

function ParseTime(time) {
	if(typeof time !== 'number') time = new Date(time).getTime()
	const rel = (Date.now() - time) / 1000
	const date = new Date(+time)
	if(rel < 60) return 'меньше минуты назад'
	else if(rel < 3600) {
		const minute = Math.floor(rel / 60)
		const lastNum = minute % 10
		if(lastNum === 1) return minute + ' минута назад'
		else if(lastNum > 1 && lastNum < 5) return minute + ' минуты назад'
		else return minute + ' минут назад'
	}
	else if(rel < 3600 * 24) {
		const hour = Math.floor(rel / 3600)
		const lastNum = hour % 10
		if(lastNum === 1) return hour + ' час назад'
		else if(lastNum > 1 && lastNum < 5) return hour + ' часа назад'
		else return hour + ' часов назад'
	}
	else {
		const day = date.getDate()
		const now = new Date().getDate()
		
		const time_ = parseTime(date)
		
		if(now === day - 1) return 'вчера в ' + time_
		else if(now === day - 1) return 'позавчера в ' + time_
		
		const month = Months[date.getMonth()]
		
		return date.getDate() + ' ' + month + ' в ' + time_
	}
}

const parseTime = date => {
	return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
}

const Timer = props => {
	const saved = localStorage.getItem(props.name)
	
	const [ left, setLeft ] = useState( (saved - Date.now()) / 1000 )
	const begin = useMemo(() => Date.now(), [])
	
	useEffect(() => {
		
		const interval = setInterval(() => {
			const _left = left - (Date.now() - begin) / 1000
			if(_left < 0) {
				props.setTimer(false)
				localStorage.removeItem(props.name)
				clearInterval(interval)
			}
			else setLeft(_left)
		}, 1000)
		
		return () => {
			clearInterval(interval)
		}
	}, [])
	
	return <>{ left.toFixed() }</>
}

export {
	ParseTime,
	Months,
	Timer
}